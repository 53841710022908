.alert {
  max-width: 500px;
  margin: 50px auto;
}

.map {
  width: 100%;
  height: 100%;
  min-height: 480px;
}
