.layout {
  width: 100%;
  height: 100%;
  background: #fff;
}
.content {
  position: relative;
}

.footer {
  text-align: center;
}
